import request from "@/utils/request";

export function index(params) {
  return request({
    url: '/new-calendar/get-data',
    method: 'get',
    params: params
  })
}

export function recalculateStaffMonth(params) {
  return request({
    url: '/new-calendar/recalculateStaffMonth',
    method: 'get',
    params: params
  })
}
